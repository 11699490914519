import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2>Abhishek Krishnan</h2>
        <div className="prompt">
          <p>A software developer looking to impact through innovation</p>
          <LinkedInIcon />
          <GithubIcon />
        </div>
      </div>
      <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
        <li className="item">
            <h2>Languages</h2>
            <span> Python, JavaScript/HTML/CSS, TypeScript, R, Solidity, SQL</span>
          </li>
          <li className="item">
            <h2> Front-End</h2>
            <span>
              ReactJS, Angular, HTML, CSS, React Native, NPM,
              BootStrap, Yarn, TailwindCSS, StyledComponents
            </span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              NodeJS, ExpressJS, MongoDB,
              MySQL, NoSQL
            </span>
          </li>
          <li className="item">
            <h2>Libraries</h2>
            <span>
              Pandas, Pandas DataReader, Matplotlib, NumPy, scikit-learn, Keras
            </span>
          </li>
          <li className="item">
            <h2>Tools + Frameworks</h2>
            <span>
              Git, Streamlit, Jupyter Notebook, Anaconda, GitLab
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
