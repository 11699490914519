import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.jpg";
import Proj3 from "../assets/proj3.webp";
import Proj4 from "../assets/proj4.webp";
import Proj5 from "../assets/proj5.jpg";
import Proj6 from "../assets/proj6.png";


export const ProjectList = [
  {
    
    name: "LSTM Stock Trend Indicator",
    image: Proj1,
    skills: "Python, Pandas, Matplotlib, NumPy, scikit-learn, Keras"
  },
  {
    name: "MERN-Todo List",
    image: Proj2,
    skills: "Javascript, MongoDB, Express.js, React, Node.js",
  },
  {/*
  {
    name: "Spotify Clone",
    image: Proj3,
    skills: "React,Node.js,MongoDB,SpotifyAPI",
  },
  {
    name: "Social Media Website",
    image: Proj4,
    skills: "React,Node.js,MySQL,GraphQL",
  },
  {
    name: "Dashboard Visualizer",
    image: Proj5,
    skills: "JavaScript,HTML,CSS",
  },
  {
    name: "Mobile Game",
    image: Proj6,
    skills: "React Native,JavaScript,HTML,CSS",
  },
*/}
];
